import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Profile } from '../model/profile';
import { firestore } from "firebase";
import { ThrowStmt } from '@angular/compiler';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstitutionProgramService {

  constructor(private db: AngularFirestore) { }

  async promote(institutionProgram: Profile, key: string) {
    const addresses = institutionProgram.addresses;
    delete institutionProgram.addresses;
    const steps = institutionProgram.steps;
    delete institutionProgram.steps;
    institutionProgram.telephones = [];
    // Insert or Update Intitution Program in firestore
    await this.db.collection('institution_program').doc(key).set(JSON.parse(JSON.stringify(institutionProgram)));

    //Delete if exists old addresses
    const addressesToDelete : firebase.firestore.QuerySnapshot = await this.db.collection('institution_program').doc(key).collection('addresses').ref.get();
    addressesToDelete.forEach(async doc => {
      await doc.ref.delete();
    });

    //Insert New Addresses
    addresses.forEach(async (address, index) => {
      await this.db.collection('institution_program').doc(key).collection('addresses').doc(`addresses_${index + 1}`).set(JSON.parse(JSON.stringify(address)));
    });

    //Delete if exists old steps
    const stepsToDelete : firebase.firestore.QuerySnapshot = await this.db.collection('institution_program').doc(key).collection('steps').ref.get();
    stepsToDelete.forEach(async doc => {
      await doc.ref.delete();
    });

    //Insert new steps collection
    steps.forEach(async (step, index) => {
      await this.db.collection('institution_program').doc(key).collection('steps').doc(`step_${index + 1}`).set(JSON.parse(JSON.stringify(step)));
    });
  }

  async delete(key : string){
    return this.db.collection('institution_program').doc(key).delete();
  }
}
