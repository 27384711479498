import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Profile } from '../model/profile';
import { firestore } from "firebase";
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private db: AngularFirestore) { }

  insert(profile: Profile) {
    return this.db.collection<Profile>('profile').add(JSON.parse(JSON.stringify(profile)));
  }

  update(profile: Profile, key: string) {
    return this.db.collection<Profile>('profile').doc(key).update(JSON.parse(JSON.stringify(profile)));
  }

  updateStatus (status: string, key: string) {
    return this.db.collection<Profile>('profile').doc(key).update({status});
  }

  getAll() {
    return this.db.collection<Profile>('profile', ref => ref.orderBy('name', 'asc')).snapshotChanges().pipe(map(actions => {
      return actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      });
    }));
  }

  getById(id: string) {
    return this.db.doc<Profile>(`profile/${id}`)
      .get()
      .pipe(
        map((value: firestore.DocumentSnapshot) => {
          const data: Object = value.data() as Profile;
          const id = value.id;
          return {
            id,
            ...data,
          } as Profile;
        }),
      );
  }

  getByEmail(email: string) {
    return this.db.collection<Profile>('profile', ref => ref.where('email', '==', email)).snapshotChanges().pipe(map(actions => {
      return actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      });
    }));
  }

  delete(key: string) {
    return this.db.collection<Profile>('profile').doc(key).delete();
  }

}
