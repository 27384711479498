// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig_dev: {
    apiKey: "AIzaSyD9ioVYMpdWHwd7_oTLqHCqAdLK1OfwtCw",
    authDomain: "migration-mobile.firebaseapp.com",
    databaseURL: "https://migration-mobile.firebaseio.com",
    projectId: "migration-mobile",
    storageBucket: "migration-mobile.appspot.com",
    messagingSenderId: "460465708165",
    appId: "1:460465708165:web:3c68a74cd8c196bdb41dec"
  },
  firebaseConfig: {
    apiKey: "AIzaSyBNjImlN2tA1r7WJ6T_BpH7-Nupsa0nCwo",
    authDomain: "migration-mobile-dev.firebaseapp.com",
    databaseURL: "https://migration-mobile-dev.firebaseio.com",
    projectId: "migration-mobile-dev",
    storageBucket: "migration-mobile-dev.appspot.com",
    messagingSenderId: "954503285602",
    appId: "1:954503285602:web:393563af9f18c6c2ec3a3b"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
