export const filters: Object[] = [
    { key: "Homem", value: "Homem" },
    { key: "Mulher", value: "Mulher" },
    { key: "Prefiro não informar", value: "Prefiro não informar" },
    { key: "menor18", value: "Menor de 18 anos" },
    { key: "maior18", value: "Maior de 18 anos" },
    { key: "maior60", value: "Maior de 60 anos" },
    { key: "brasileiro", value: "Brasileiro" },
    { key: "imigrante", value: "Imigrante" },
    { key: "refugiado", value: "Refugiado" },
    { key: "solicitanteRefugio", value: "Solicitante de refugio" },
    { key: "rural", value: "Rural" },
    { key: "urbano", value: "Urbano" },
    { key: "necessidadesEspeciais", value: "Necessidades especiais" }
]

export const filtersToUsers: Object[] = [
    { key: "imigrante", value: "Imigrante" },
    { key: "refugiado", value: "Refugiado" },
    { key: "solicitanteRefugio", value: "Solicitante de refúgio" },
    { key: "brasileiro", value: "Brasileiro" },
    { key: "Mulher", value: "Mulher" },
    { key: "LGBTI", value: "LGBTI" },
    { key: "Idosos", value: "Idosos" },
    { key: "CriancasEJovens", value: "Crianças e Jovens" },
    { key: "necessidadesEspeciais", value: "Necessidades especiais" }
]
