import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { createI18nForm } from 'src/app/utils/i18n';
import { modalStepToStep } from '../../shared/modal-step-to-step/modal-step-to-step';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../../../services/auth.service';

@Component({
  selector: 'app-steps-form',
  templateUrl: './steps-form.component.html',
  styleUrls: ['./steps-form.component.css'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class StepsFormComponent implements OnInit {

  @Input() stepsFormGroup: FormGroup;

  constructor(public dialog: MatDialog, public authenticationService: AuthenticationService) { }

  ngOnInit() {
  }

  onSubmit() {
    const dialogRef = this.dialog.open(modalStepToStep, {
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    });
  }

  addStep() {
    (this.stepsFormGroup.get('steps') as FormArray).push(StepsFormComponent.createStep());
  }

  removeStep(index: number) {
    (this.stepsFormGroup.get('steps') as FormArray).removeAt(index);
  }

  static createStep(): FormGroup {
    return new FormGroup({
      description: createI18nForm(),
      link: new FormControl(''),
      requirements: new FormControl(''),
      google: new FormControl(''),
      order: new FormControl('')
    });
  }
}
