import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SiteComponent } from './components/site/site.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { VerifyComponent } from './components/verify/verify.component';
import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from './guard/auth.guard';
import { AdminGuard } from './guard/admin.guard';
import { ProfilesListComponent } from './components/profile/list/list.component';
import { EditComponent as EditProfileComponent } from './components/profile/edit/edit.component';
import { PreRegistrationComponent } from './components/pre-registration/pre-registration.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'site' },
  { path: 'site', component: SiteComponent },
  { path: 'login', component: LoginComponent },
  { path: 'pre-registration', component: PreRegistrationComponent },
  { path: 'verify', component: VerifyComponent },

  { path: 'home', component: HomeComponent, canActivate : [AuthGuard] },
  { path: 'profile/edit/:key', component: EditProfileComponent, canActivate : [AuthGuard]  },
  
  { path: 'register', component: RegisterComponent, canActivate : [AuthGuard, AdminGuard] },
  { path: 'profile/list', component: ProfilesListComponent, canActivate : [AuthGuard, AdminGuard]  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
