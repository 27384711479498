import { Component } from '@angular/core';
import { AuthenticationService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { FormControl,  Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required]);
  alertMessage : string;

  constructor(public authenticationService: AuthenticationService, private router: Router) { }

  login() {
    if (!this.email.invalid && !this.password.invalid){
      this.authenticationService.SignIn(this.email.value, this.password.value).then(res => {
        /* Successfully signed in! */
        this.alertMessage = "";
        this.router.navigate(['/home']);
      })
        .catch(err => {
          console.error('Something is wrong:', err);
          this.alertMessage = err.message;
        });
    }
  }

  getAlertMessage(){
    return this.alertMessage;
  }
}
