import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/auth.service';
import { ProfileService } from 'src/app/services/profile.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Profile } from '../../model/profile';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  constructor(public authenticationService: AuthenticationService, private router: Router, private profileService: ProfileService) { }
  
  profiles: Observable<Profile[]>;
  
  ngOnInit() {
    this.profiles = this.profileService.getByEmail(localStorage.userEmail);
  }

  isAdmin(){
    return this.authenticationService.userRole === 'admin';
  }
}
