import { BrowserModule } from '@angular/platform-browser';

/* Routing */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/* Firebase services + enviorment module */
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule  } from "@angular/fire/firestore";
import { environment } from '../environments/environment';

/* Auth service */
import { AuthenticationService } from './services/auth.service';

/* Components */
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { VerifyComponent } from './components/verify/verify.component';

/* Angular material */
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

/* CKEditor Ecosystem (TextArea) */
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

/* Angular Flex Layout */
import { FlexLayoutModule } from '@angular/flex-layout';

/* Http module */
import { HttpClientModule } from '@angular/common/http';

/* FormsModule */
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './components/home/home.component';
import { PreRegistrationComponent } from './components/pre-registration/pre-registration.component';
import { ProfilesListComponent } from './components/profile/list/list.component';
import { EditComponent } from './components/profile/edit/edit.component';
import { ConfirmationDialogComponent } from './components/shared/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogComponentTranslate } from './components/shared/confirmation-dialog-translate/confirmation-dialog.component';
import { modalStepToStep } from './components/shared/modal-step-to-step/modal-step-to-step';
import { FirebaseProvider } from './providers/firebase/firebase';
import { AddressFormComponent } from './components/shared/address-form/address-form.component';
import { StepsFormComponent } from './components/shared/steps-form/steps-form.component';
import { SiteComponent } from './components/site/site.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    VerifyComponent,
    HomeComponent,
    PreRegistrationComponent,
    ProfilesListComponent,
    EditComponent,
    ConfirmationDialogComponent,
    ConfirmationDialogComponentTranslate,
    modalStepToStep,
    AddressFormComponent,
    StepsFormComponent,
    SiteComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule ,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    CKEditorModule,
    HttpClientModule
  ],
  entryComponents: [
    ConfirmationDialogComponent,ConfirmationDialogComponentTranslate,modalStepToStep
  ],
  providers: [AuthenticationService, FirebaseProvider],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }