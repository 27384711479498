import { Component } from '@angular/core';
import { AuthenticationService } from './services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  
  constructor(public authenticationService: AuthenticationService, private router: Router) {}

  logout() {
    this.authenticationService.SignOut();
  }

  isAdmin(){
    return this.authenticationService.userRole === 'admin';
  }

}