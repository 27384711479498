import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponentTranslate } from '../shared/confirmation-dialog-translate/confirmation-dialog.component';
import { IService } from 'src/app/utils/interfaces';
import { FirebaseProvider } from 'src/app/providers/firebase/firebase';
import { InstitutionProgramService } from 'src/app/services/institution-program.service';
import { AddressFormComponent } from '../shared/address-form/address-form.component';
import { StepsFormComponent } from '../shared/steps-form/steps-form.component';
import { createI18nForm } from 'src/app/utils/i18n';
import { filtersToUsers } from 'src/app/utils/filters';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import { Validators, FormControl, FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { ProfileService } from 'src/app/services/profile.service';
import { Profile } from '../../model/profile';
import { typesToUsers } from 'src/app/utils/constants';
import { SendMailService } from 'src/app/services/send-mail.service';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-edit',
  templateUrl: './pre-registration.component.html',
  styleUrls: ['./pre-registration.component.css']
})
export class PreRegistrationComponent implements OnInit {

  private types: Object[] = typesToUsers;
  private services: IService[];
  private filters: Object[] = filtersToUsers;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(private route: ActivatedRoute,
    private firebaseProvider: FirebaseProvider,
    private profileService: ProfileService,
    private institutionProgramService: InstitutionProgramService,
    private router: Router,
    private sendMailService: SendMailService,
    private translateService: TranslateService,
    public dialog: MatDialog) {
    this.firebaseProvider
      .getAllServices()
      .subscribe((services) => {
        this.services = services;
      });
  }

  userProfile = null;
  userProfileOld = null;

  preRegistrationForm = new FormGroup({
    name: createI18nForm(),
    type: new FormControl('', [Validators.required]),
    description: createI18nForm(),
    email: new FormControl('', [Validators.required, Validators.email]),
    link: new FormControl(''),
    telephones: new FormControl([]),
    status: new FormControl(''),
    addresses: new FormArray([]),
    steps: new FormArray([]),
    filters: new FormControl([])
  });
  key: string;
  alertMessage: string;

  onSubmit() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponentTranslate, {
          data: `Você deseja confirmar esse cadastro?`
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            let profile: Profile = new Profile(this.preRegistrationForm.value);
            profile.status = 'pending';
            console.log(profile)
            profile.name.fr = profile.name.en;
            profile.name.es = profile.name.en;
            profile.name.pt = profile.name.en;
            profile.description.fr = profile.description.en;
            profile.description.es = profile.description.en;
            profile.description.pt = profile.description.en;

            this.userProfile = profile;

            this.profileService.insert(profile)
            .then(() => {
              this.sendMailService.sendMailConfirmRegister(this.userProfile.email, this.userProfile.name.en);
              this.router.navigate(['/']);
            }).catch((error: any) => {
              console.error(error);
              this.alertMessage = error.message;
            });
          }
        });
      
    }

  ngOnInit() { }


  getAlertMessage() {
    return this.alertMessage;
  }

  addTelephone(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      let telephones = this.preRegistrationForm.controls.telephones.value;
      telephones.push(value);
      this.preRegistrationForm.controls.telephones.setValue(telephones);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeTelephone(index: number): void {
    if (index >= 0) {
      let telephones = this.preRegistrationForm.controls.telephones.value;
      telephones.splice(index, 1);
      this.preRegistrationForm.controls.telephones.setValue(telephones);
    }
  }
}
