import { Component, OnInit,ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ProfileService } from 'src/app/services/profile.service';
import { Profile } from '../../../model/profile';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { FormControl,FormGroup} from '@angular/forms';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ProfilesListComponent implements OnInit {

  profiles = new MatTableDataSource();
  statusSelected = 'approved';

  constructor(private profileService: ProfileService) {

    
    this.profileService.getAll().subscribe( 
      (data :any) => { 
      this.profiles = new MatTableDataSource<Profile>(data);

      this.profiles.filterPredicate = (data: any, filter) => {
        const dataStr =JSON.stringify(data).toLowerCase();
        return dataStr.indexOf(filter) != -1; 
      }
      this.profiles.sort = this.sort;
      this.profiles.paginator = this.paginator;
     
    });
    
  }
 
   
  displayedColumns: string[] = ['organization','status', 'edit'];

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;   
 
  
  nameFilter = new FormControl('');

  selectStatus : string;

  ngOnInit() {

    this.selectStatus = 'all';
    // this.selectStatus =  new FormGroup({
    //   statusSelected : new FormControl(),
      
    // });
   
    this.nameFilter.valueChanges
    .subscribe(
      name => {        
        this.profiles.filter = name.trim().toLowerCase();
      }
    )
   
  } 

  changeStatus(status: string){
    if(status == 'all'){
      this.profiles.filter = '';
    }else{
      this.profiles.filter = status.trim().toLowerCase();
    }
    
  }

}