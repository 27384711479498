import { II18nContent, ISteps, IAddress } from '../utils/interfaces';

export class Profile {
    public constructor(init?: Partial<Profile>) {
        Object.assign(this, init);
    }
    id: string;
    name: II18nContent;
    type?: string;
    email: string;
    link?: string;
    addresses: IAddress[];
    status: string;
    description?: II18nContent;
    services?: string[];
    filters?: string[];
    steps?: ISteps[];
    filter: string[];
    isEmergency: boolean;
    telephones: string[];
    cities: string[];
}
