import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Profile } from '../../../model/profile';
import { ProfileService } from 'src/app/services/profile.service';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { types } from 'src/app/utils/constants';
import { typesToUsers } from 'src/app/utils/constants';
import { IService } from 'src/app/utils/interfaces';
import { FirebaseProvider } from 'src/app/providers/firebase/firebase';
import { InstitutionProgramService } from 'src/app/services/institution-program.service';
import { AddressFormComponent } from '../../shared/address-form/address-form.component';
import { StepsFormComponent } from '../../shared/steps-form/steps-form.component';
import { createI18nForm } from 'src/app/utils/i18n';
import { filters } from 'src/app/utils/filters';
import { filtersToUsers } from 'src/app/utils/filters';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import { AuthenticationService } from '../../../services/auth.service';
import { SendMailService } from 'src/app/services/send-mail.service';


@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {

  private types: Object[] = types;
  private typesToUsers: Object[] = typesToUsers;
  private services: IService[];
  private filters: Object[] = filters;
  private filtersToUsers: Object[] = filtersToUsers;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(private route: ActivatedRoute,
    private firebaseProvider: FirebaseProvider,
    private profileService: ProfileService,
    private institutionProgramService: InstitutionProgramService,
    public authenticationService: AuthenticationService,
    private router: Router,
    private sendMailService: SendMailService,
    public dialog: MatDialog) {
    this.firebaseProvider
      .getAllServices()
      .subscribe((services) => {
        this.services = services;
      });
  }

  
  isAdmin(){
    return this.authenticationService.userRole === 'admin';
  }


  profileForm = new FormGroup({
    name: createI18nForm(),
    type: new FormControl('', [Validators.required]),
    description: createI18nForm(),
    email: new FormControl('', [Validators.required, Validators.email]),
    link: new FormControl(''),
    telephones: new FormControl([]),
    status: new FormControl('', [Validators.required]),
    addresses: new FormArray([]),
    steps: new FormArray([]),
    isEmergency: new FormControl(''),
    services: new FormControl([]),
    filters: new FormControl([])
  });
  key: string;
  alertMessage: string;

  //public Editor = ClassicEditor;

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.key = params['params'].key;
      this.profileService.getById(this.key).subscribe(_profile => {
        this.createForm(new Profile(_profile));
      });
    });
  }

  createForm(profile: Profile) {
    for (let line = 0; line < profile.addresses.length; line++) {
      const linesFormArray = this.profileForm.get("addresses") as FormArray;
      linesFormArray.push(AddressFormComponent.createAddress());
    }
    if (profile.steps) {
      for (let line = 0; line < profile.steps.length; line++) {
        const linesFormArray = this.profileForm.get("steps") as FormArray;
        linesFormArray.push(StepsFormComponent.createStep());
      }
    }
    this.profileForm.patchValue(profile);
  }

  delete(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: `Do you confirm the deletion of ${this.profileForm.value.name.en}?`
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        try {
          await this.profileService.delete(this.key);
          await this.institutionProgramService.delete(this.key);
          this.router.navigate(['/profile/list']);
        } catch (error) {
          console.error(error);
          this.alertMessage = error.message;
        }
      }
    });
  }

  publish(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: `Do you confirm the publishing of ${this.profileForm.value.name.en}?`
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        let profile: Profile = new Profile(this.profileForm.value);
        //Setting order of steps info;
        profile.steps.map((step, index) => {
          step.order = index + 1;
        })
        //Populate cities from address
        profile.cities = [];
        profile.addresses.map(address => profile.cities.push(address.city));
        this.save(profile, false);
        try {
          await this.institutionProgramService.promote(profile, this.key);
          this.router.navigate(['/profile/list']);
        } catch (error) {
          console.error(error);
          this.alertMessage = error.message;
        }
      }
    });
  }

  save(profile: Profile, redirect: boolean): void {
    this.profileService.update(profile, this.key)
      .then(() => {
        if (redirect)
          this.router.navigate(['/profile/list']);
      }).catch((error: any) => {
        console.error(error);
        this.alertMessage = error.message;
      });

  }

  onSubmit() {
    if (this.profileForm.valid) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: `Deseja realmente salvar este perfil?`
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          let profile: Profile = new Profile(this.profileForm.value);
          //Setting order of steps info;
          profile.steps.map((step, index) => {
            step.order = index + 1;
          })
          //Populate cities from address
          profile.cities = [];
          profile.addresses.map(address => profile.cities.push(address.city));
          this.save(profile, true);
        }
      });
    }
  }

  getAlertMessage() {
    return this.alertMessage;
  }

  async updateStatus(status: string){
    console.log(status)
    if (status === 'approved'){
      await this.profileService.updateStatus(status, this.key).then(() => {
        const name = this.profileForm.controls.name;
        this.sendMailService.sendMailInstitutionAccepted(this.profileForm.controls.email.value, name.get('en').value);
        this.router.navigate(['/register', {email: this.profileForm.controls.email.value, name: name.get('en').value} ]);
      })
    }
    else if (status === 'rejected'){
      console.log('entrou')
      await this.profileService.updateStatus(status, this.key).then(() => {
        const name = this.profileForm.controls.name;
        this.sendMailService.sendMailInstitutionRejected(this.profileForm.controls.email.value, name.get('en').value);
        this.router.navigate(['/profile/list'])
      });
    }else {
      await this.profileService.updateStatus(status, this.key);
    }
  }
  

  addTelephone(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      let telephones = this.profileForm.controls.telephones.value;
      telephones.push(value);
      this.profileForm.controls.telephones.setValue(telephones);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeTelephone(index: number): void {
    if (index >= 0) {
      let telephones = this.profileForm.controls.telephones.value;
      telephones.splice(index, 1);
      this.profileForm.controls.telephones.setValue(telephones);
    }
  }
}
