import { Injectable } from '@angular/core';
import { firestore } from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { Profile } from '../model/profile';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  constructor(private db: AngularFirestore) { }

  insert(profileNew: Profile, profileOld: Profile) {

    let profile = null;
    profile = new Profile();
    profile.description={'pt':''};
    profile.steps = [];
    let toWrite = false;
    //Verifica se houve mudança no campo texto, para saber se envio ou nao para a base translate
    if(profileNew.description.pt != profileOld.description.pt) {
      profile.description.pt = profileNew.description.pt
    }
    for(let idx=0;profileNew.steps.length>idx;idx++){
      if(profileOld.steps[idx]){
        if(profileNew.steps[idx].description.pt != profileOld.steps[idx].description.pt) {
          profile.steps[idx].description.pt = profileNew.steps[idx].description.pt;
          toWrite=true;
        }
      } else {
        if(!profile.steps[idx]) {
          profile.steps[idx] = { 'description' : {'pt':''} };
        }
        profile.steps[idx].description.pt = profileNew.steps[idx].description.pt;
        toWrite=true;
      }
    }
    
    let returnDB = null;
    if(toWrite) {
      returnDB = this.db.collection<Profile>('translate').add(JSON.parse(JSON.stringify(profile)));
    }

    return returnDB;
  }

  async getById(id: string) {
    const user = await this.db.collection('users').doc(id).ref.get();
    return user.data();
  }

  setUserRole(id: string, role: string) {
    this.db.collection('users').doc(id).set({role});
  }

}
