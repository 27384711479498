declare var require: any
import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, FormArray, ControlContainer, FormGroupDirective, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { state } from '@angular/animations';
const cities = require("../../../utils/cities.json");

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.css'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class AddressFormComponent implements OnInit {
  private estados: Object[] = cities.states;
  private cidades: Object[] = cities.cities;
  private citiesFiltered: any = [];

  @Input() addressFormGroup: FormGroup;

  constructor() { 
  }

  ngOnInit() {
  }

  addAddress() {
    (this.addressFormGroup.get('addresses') as FormArray).push(AddressFormComponent.createAddress());
  }

  static createAddress(): FormGroup {
    return new FormGroup({
      completeAddress: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      cep: new FormControl(''),
    });
  }

  changeState(estado: string){
    this.citiesFiltered = this.cidades[estado];
  }

  removeAddress(index: number) {
    (this.addressFormGroup.get('addresses') as FormArray).removeAt(index);
  }

  get state(): AbstractControl[] {
    return this.addressFormGroup.get('addresses').value;
  }

}
