import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

//import { URLSearchParams } from 'url';

@Injectable({
  providedIn: 'root'
})
export class SendMailService {

  constructor(private http: HttpClient) { }

  sendMailConfirmRegister(email, name) {

    let url = 'https://us-central1-migration-mobile-dev.cloudfunctions.net/sendmailconfirmregister?email='+email+'&name='+name
    //let params: URLSearchParams = new URLSearchParams();
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' })
    //};

    //params.set('to', 'user@example.com');
    //params.set('from', 'you@yoursupercoolapp.com');
    //params.set('subject', 'test-email');
    //params.set('content', 'Hello World');

    return this.http.get(url)
      .toPromise()
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        console.log(err)
      })
  }

  sendMailInstitutionRejected(email, name) {
    
    let url = 'https://us-central1-migration-mobile-dev.cloudfunctions.net/sendmailrejected?email='+email+'&name='+name

    return this.http.get(url)
      .toPromise()
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        console.log(err)
      })
  }
  sendMailInstitutionAccepted(email, name) {
    
    let url = 'https://us-central1-migration-mobile-dev.cloudfunctions.net/sendmailaccepted?email='+email+'&name='+name

    return this.http.get(url)
      .toPromise()
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        console.log(err)
      })
  }
}