import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  userData: Observable<firebase.User>;
  userRole: string;
  userEmail: string;

  constructor(private angularFireAuth: AngularFireAuth, private router: Router, private userService: UserService) {
    this.userData = angularFireAuth.authState;
    this.userData.subscribe(async user => {
      if (user){
        const data = await userService.getById(user.uid);
        this.userRole = data.role;
        localStorage.setItem('userEmail', user.email);
      }
    })
  }

  /* Sign up */
  SignUp(email: string, password: string) {
    return this.angularFireAuth
      .auth
      .createUserWithEmailAndPassword(email, password);
  }

  /* Sign in */
  SignIn(email: string, password: string) {
    return this.angularFireAuth
      .auth
      .signInWithEmailAndPassword(email, password);
  }

  /* Sign out */
  SignOut() {
    this.angularFireAuth.auth.signOut();
    this.userData.subscribe(() => {
      window.location.href = '/login';
    });
  }

}