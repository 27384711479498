import { Injectable } from '@angular/core';
import { firestore } from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private db: AngularFirestore) { }

  async getById(id: string) {
    const user = await this.db.collection('users').doc(id).ref.get();
    return user.data();
  }

  setUserRole(id: string, role: string) {
    this.db.collection('users').doc(id).set({role});
  }

}
