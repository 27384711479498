export class InstitutionTypesConstants{
  public static DOCUMENT = 'documento'; //nao filtra por endereco
  public static INSTITUTION = 'instituicao'; //nao filtra por endereco
  public static FEDERAL_PROGRAM = 'programaFederal'; //nao filtra por endereco
  public static PUBLIC_SERVICE = 'servicoPublico'; //nao filtra por endereco
  public static FEDERAL_SERVICE = 'servicoFederal'; //nao filtra por endereco
  public static STATE_PROGRAM = 'programaEstadual';
  public static CITY_PROGRAM = 'programaMunicipal';
  public static ONG = 'ONG';
  public static INTERNATIONAL_AGENCY = 'agenciaInternacional';
  public static UNIVERSITY_PROGRAM = 'programaUniversitario';
  public static EMERGENCY = 'emergencia';
  public static STATE_INSTITUTION = "instituicaoEstadual";
  public static PRIVATE_SERVICE = 'instituicaoPrivada';
}

export const typesToUsers: Object[] = [
  {key: InstitutionTypesConstants.INTERNATIONAL_AGENCY, value: "Agência internacional"},
  {key: InstitutionTypesConstants.ONG, value: "ONG/Sociedade civil"},
  {key: InstitutionTypesConstants.PUBLIC_SERVICE, value: "Instituição Pública"},
  {key: InstitutionTypesConstants.PRIVATE_SERVICE, value: "Instituição Privada"},
];

export const types: Object[] = [
  {key: InstitutionTypesConstants.DOCUMENT, value: "Document"},
  {key: InstitutionTypesConstants.INSTITUTION, value: "Institution"},
  {key: InstitutionTypesConstants.FEDERAL_PROGRAM, value: "Federal Program"},
  {key: InstitutionTypesConstants.PUBLIC_SERVICE, value: "Public Service"},
  {key: InstitutionTypesConstants.FEDERAL_SERVICE, value: "Federal Service"},
  {key: InstitutionTypesConstants.CITY_PROGRAM, value: "City Program"},
  {key: InstitutionTypesConstants.ONG, value: "ONG"},
  {key: InstitutionTypesConstants.INTERNATIONAL_AGENCY, value: "International Agency"},
  {key: InstitutionTypesConstants.UNIVERSITY_PROGRAM, value: "University Program"},
  {key: InstitutionTypesConstants.EMERGENCY, value: "Emergency"},
  {key: InstitutionTypesConstants.STATE_INSTITUTION, value: "State Institution"}
];


export const services: Object[] = [
  {key: InstitutionTypesConstants.DOCUMENT, value: "Document"},
  {key: InstitutionTypesConstants.INSTITUTION, value: "Institution"},
  {key: InstitutionTypesConstants.FEDERAL_PROGRAM, value: "Federal Program"},
  {key: InstitutionTypesConstants.PUBLIC_SERVICE, value: "Public Service"},
  {key: InstitutionTypesConstants.FEDERAL_SERVICE, value: "Federal Service"},
  {key: InstitutionTypesConstants.CITY_PROGRAM, value: "City Program"},
  {key: InstitutionTypesConstants.ONG, value: "ONG"},
  {key: InstitutionTypesConstants.INTERNATIONAL_AGENCY, value: "International Agency"},
  {key: InstitutionTypesConstants.UNIVERSITY_PROGRAM, value: "University Program"},
  {key: InstitutionTypesConstants.EMERGENCY, value: "Emergency"},
  {key: InstitutionTypesConstants.STATE_INSTITUTION, value: "State Institution"}
];

export const roles : Object[] = [
  { key: "admin", value: "Admin" },
  { key: "user", value: "User" }
];