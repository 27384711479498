import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/auth.service';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { InstitutionTypesConstants } from 'src/app/utils/constants';
import { roles } from 'src/app/utils/constants';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {

  
  private roles: Object[] = roles;
  
  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required]);
  confirmPassword = new FormControl('', [Validators.required]);
  displayName = new FormControl('', [Validators.required]);
  role = new FormControl('', [Validators.required]);
  alertMessage: string;
  
  constructor(private route: ActivatedRoute, public authenticationService: AuthenticationService, private router: Router, private userService : UserService) { }
  
  ngOnInit(): void {
    this.route.params.subscribe(params => {
       this.email.setValue(params['email']);
       this.displayName.setValue(params['name']);
    });
  }

  register() {
    if (this.password.value == this.confirmPassword.value) {
      this.alertMessage = "";
      this.authenticationService.SignUp(this.email.value, this.password.value).then(async res => {
        console.log('Successfully signed up!', res);
        try {
          await res.user.updateProfile({ displayName: this.displayName.value });
          await this.userService.setUserRole(res.user.uid, this.role.value);
          this.router.navigate(['/home']);
        } catch (error) {
          console.error('Something is wrong:', error);
          this.alertMessage = error.message;
        }
      }).catch(error => {
        console.error('Something is wrong:', error);
        this.alertMessage = error.message;
      });
    } else {
      this.alertMessage = "Password mismatch";
    }
  }

  getAlertMessage() {
    return this.alertMessage;
  }
  
}