import { Injectable } from "@angular/core";
import { AngularFirestore, DocumentChangeAction } from "@angular/fire/firestore";
import { firestore } from "firebase";
import { map } from "rxjs/operators";

import {
  IAddress,
  ICategory,
  ICommunity, IControl,
  IInstitutionProgram,
  IQuestion,
  IService,
  ISteps,
} from "../../utils/interfaces";

/*
 * Generated class for the FirebaseProvider provider.
 *
 * See https://angular.io/guide/dependency-injection for more info on providers
 * and Angular DI.
 */
@Injectable()
export class FirebaseProvider {
  constructor(private afStore: AngularFirestore) {}

  getDatabaseControl() {
    return this.afStore.doc<IControl>("database_control/status")
               .get()
               .pipe(
                 map((value: firestore.DocumentSnapshot) => {
                   const data: Object = value.data() as IControl;
                   // const id = value.id;

                   return {
                     ...data,
                   } as IControl;
                 }),
               );
  }

  getCategories() {
    return this.afStore
               .collection<ICategory>("categories")
               .snapshotChanges()
               .pipe(
                 map((actions: DocumentChangeAction<ICategory>[]) => {
                   return actions.map((value: DocumentChangeAction<ICategory>) => {
                     const data: Object = value.payload.doc.data() as ICategory;
                     const id = value.payload.doc.id;

                     return {
                       id,
                       ...data,
                     } as ICategory;
                   });
                 }),
               );
  }

  getCategoriesByID(id: string) {
    return this.afStore.doc<ICategory>(`categories/${ id }`)
               .get()
               .pipe(
                 map((value: firestore.DocumentSnapshot) => {
                   const data: Object = value.data() as ICategory;
                   const id = value.id;

                   return {
                     id,
                     ...data,
                   } as ICategory;
                 }),
               );
  }

  getCommunities() {
    return this.afStore
               .collection<ICommunity>("communities")
               .snapshotChanges()
               .pipe(
                 map((actions: DocumentChangeAction<ICommunity>[]) => {
                   return actions.map((value: DocumentChangeAction<ICommunity>) => {
                     const data: Object = value.payload.doc.data() as ICommunity;
                     const id = value.payload.doc.id;

                     return {
                       id,
                       ...data,
                     } as ICommunity;
                   });
                 }),
               );
  }

  getAllServices() {
    return this.afStore
               .collection<IService>("services")
               .snapshotChanges()
               .pipe(
                 map((actions: DocumentChangeAction<IService>[]) => {
                   return actions.map((value: DocumentChangeAction<IService>) => {
                     const data: Object = value.payload.doc.data() as IService;
                     const id = value.payload.doc.id;

                     return {
                       id,
                       ...data,
                     } as IService;
                   });
                 }),
               );
  }

  getServicesByCategory(category: string) {
    return this.afStore
               .collection<IService>(
                 "services",
                 (ref) => ref.where("category", "==", category),
               )
               .snapshotChanges()
               .pipe(
                 map((actions: DocumentChangeAction<IService>[]) => {
                   return actions.map((value: DocumentChangeAction<IService>) => {
                     const data: Object = value.payload.doc.data() as IService;
                     const id = value.payload.doc.id;

                     return {
                       id,
                       ...data,
                     } as IService;
                   });
                 }),
               );
  }

  getIntitutionsProgramsByCategory(category: string) {
    return this.afStore
               .collection<IInstitutionProgram>(
                 "institution_program",
                 (ref) => ref.where("categories", "array-contains", category),
               )
               .snapshotChanges()
               .pipe(
                 map((actions: DocumentChangeAction<IInstitutionProgram>[]) => {
                   return actions.map((value: DocumentChangeAction<IInstitutionProgram>) => {
                     const data: Object = value.payload.doc.data() as IInstitutionProgram;
                     const id = value.payload.doc.id;

                     return {
                       id,
                       ...data,
                     } as IInstitutionProgram;
                   });
                 }),
               );
  }

  getInstitutionPrograms() {
    return this.afStore
               .collection<IInstitutionProgram>("institution_program")
               .snapshotChanges()
               .pipe(
                 map((actions: DocumentChangeAction<IInstitutionProgram>[]) => {
                   return actions.map((value: DocumentChangeAction<IInstitutionProgram>) => {
                     const data: Object = value.payload.doc.data() as IInstitutionProgram;
                     const id = value.payload.doc.id;

                     return {
                       id,
                       ...data,
                     } as IInstitutionProgram;
                   });
                 }),
               );
  }

  getInstitutionProgramAddresses(code: string) {
    return this.afStore
               .collection<IAddress>(`institution_program/${ code }/addresses`)
               .snapshotChanges()
               .pipe(
                 map((actions: DocumentChangeAction<IAddress>[]) => {
                   return actions.map((value: DocumentChangeAction<IAddress>) => {
                     const data: Object = value.payload.doc.data() as IAddress;
                     const id = value.payload.doc.id;

                     return {
                       id,
                       ...data,
                     } as IAddress;
                   });
                 }),
               );
  }

  getInstitutionProgramSteps(code: string) {
    return this.afStore
               .collection<ISteps>(`institution_program/${ code }/steps`)
               .snapshotChanges()
               .pipe(
                 map((actions: DocumentChangeAction<ISteps>[]) => {
                   return actions.map((value: DocumentChangeAction<ISteps>) => {
                     const data: Object = value.payload.doc.data() as ISteps;
                     const id = value.payload.doc.id;

                     return {
                       id,
                       ...data,
                     } as ISteps;
                   });
                 }),
               );
  }

  getInstitutionsProgramsByCode(code: string) {
    return this.afStore.doc<IInstitutionProgram>(`institution_program/${ code }`)
               .get()
               .pipe(
                 map((value: firestore.DocumentSnapshot) => {
                   const data: Object = value.data() as IInstitutionProgram;
                   const id = value.id;

                   return {
                     id,
                     ...data,
                   } as IInstitutionProgram;
                 }),
               );
  }

  getQuestions() {
    return this.afStore
               .collection<IQuestion>("questions")
               .snapshotChanges()
               .pipe(
                 map((actions: DocumentChangeAction<IQuestion>[]) => {
                   return actions.map((value: DocumentChangeAction<IQuestion>) => {
                     const data: Object = value.payload.doc.data() as IQuestion;
                     const id = value.payload.doc.id;

                     return {
                       id,
                       ...data,
                     } as IQuestion;
                   });
                 }),
               );
  }
}
